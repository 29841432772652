import { render, staticRenderFns } from "./detailsDevoirTeacher.vue?vue&type=template&id=0a6c1a20&"
import script from "./detailsDevoirTeacher.vue?vue&type=script&lang=js&"
export * from "./detailsDevoirTeacher.vue?vue&type=script&lang=js&"
import style0 from "./detailsDevoirTeacher.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports