var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('Header'),_vm._v(" "),_c('MenuLocal'),_vm._v(" "),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"container-full"},[_c('section',{staticClass:"content"},[_c('div',{staticClass:"box box-default"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"box-body wizard-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Upload',{attrs:{"multiple":"","type":"drag","action":"api/admin/uploadstudent","data":"this.data","on-success":_vm.handleSuccess,"on-error":_vm.handleError,"format":[

    'xls',
    'xlsx'
],"on-format-error":_vm.handleFormatError,"headers":{
    'X-Requested-With':
        'XMLHttpRequest'
}}},[_c('div',{staticStyle:{"padding":"20px 0"}},[_c('Icon',{staticStyle:{"color":"#3399ff"},attrs:{"type":"ios-cloud-upload","size":"52"}}),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n                                                Fichier importation des eleves\n                                            ")])],1)])],1),_vm._v(" "),_c('div',{staticClass:"col-md-12"}),_vm._v(" "),_c('div',{staticClass:"col-md-12"}),_vm._v(" "),_c('div',{staticClass:"col-md-12"},[_c('Upload',{attrs:{"multiple":"","type":"drag","action":"api/admin/uploadsmatieres","data":"this.data","on-success":_vm.handleSuccess,"on-error":_vm.handleError,"format":[

    'xls',
    'xlsx'
],"on-format-error":_vm.handleFormatError,"headers":{
    'X-Requested-With':
        'XMLHttpRequest'
}}},[_c('div',{staticStyle:{"padding":"20px 0"}},[_c('Icon',{staticStyle:{"color":"#ff33a0"},attrs:{"type":"ios-cloud-upload","size":"52"}}),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n                                                Fichier importations des matieres\n                                            ")])],1)])],1)])])])])])])],1),_vm._v(" "),_c('Chats')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-header",staticStyle:{"background-color":"#0052CC","text-align":"center","color":"white"}},[_c('h4',{staticClass:"box-title"},[_vm._v("IMPORTATION DES ELEVES ET MATIERES ")])])}]

export { render, staticRenderFns }