<template>
    <div>
        <div class="wrapper">
            <Header />
            <MenuLocal />

            <Modal v-model="showDelateModal" width="360">
                <p slot="header" style="color:#f60;text-align:center">
                    <span> Suppression </span>
                </p>
                <div style="text-align:center">
                    <p>Etes-vous sure de vouloir supprimer ?</p>
                </div>
                <div slot="footer">
                    <Button type="error" size="large" long @click="delateParent">Confirmer</Button>
                </div>
            </Modal>

            <div class="content-wrapper" style="min-height: 653px; background-color:#FAFBFD">
                <div class="container-full">
                    <!-- Main content -->
                    <section class="content">
                        <div class="row">
                            <div class="col-12">
                                <div class="box">
                                    <div class="box-header bg-primary">
                                        <h4 class="box-title" style="margin:auto">
                                            Parents
                                        </h4>

                                        <span>
                                            <router-link to="addParent">
                                                <button type="button" class=" pull-right btn  btn-primary mb-5"
                                                    @click="modal6 = true">
                                                    <Icon type="md-person-add" />

                                                    Nouveau
                                                </button>
                                            </router-link>
                                        </span>
                                    </div>
                                    <div class="box-body">
                                        <div class="table-responsive">
                                            <table id="example" class="table simple mb-0" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Noms et prénoms </th>
                                                        <!-- <th>Prénom</th> -->
                                                        <th>Téléphone</th>
                                                        <!-- <th>Email</th> -->
                                                        <!-- <th>Profession</th> -->
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data,
                                                        i) in datas" :key="i">
                                                        <td>{{ data.id }}</td>
                                                        <td>
                                                            {{ data.nomParent }} {{
                                                                data.prenomParent
                                                            }}
                                                        </td>
                                                        <!-- <td>

                                                        </td> -->
                                                        <td>
                                                            {{ data.telParent }}
                                                        </td>
                                                        <!-- <td>{{data.emailParent}}</td> -->
                                                        <!-- <td>
                                                            {{
                                                                data.professionParent
                                                            }}
                                                        </td> -->
                                                        <td style="text-align:center" class="center">
                                                            <router-link to="enfantsParent">
                                                                <button @click="
                                                                    Details(
                                                                        data,
                                                                        i
                                                                    )
                                                                    " class="btn btn-success btn-xs"
                                                                    title="Voir ses enfants">
                                                                    <Icon type="md-apps" />
                                                                </button>
                                                            </router-link>

                                                            <router-link to="updateParent">
                                                                <button class="btn btn-primary btn-xs" @click="
                                                                    Details(
                                                                        data,
                                                                        i
                                                                    )
                                                                    " title="Modifier un parent ">
                                                                    <i class="ti-pencil"></i>
                                                                </button>
                                                            </router-link>

                                                            <button @click="
                                                                showDelatingModal(
                                                                    data,
                                                                    i
                                                                )
                                                                " class="btn btn-danger btn-xs" title="Supprimer">
                                                                <i class="ti-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- /.col -->
                        </div>
                        <!--
                        <b-pagination :total-rows="rows" @change="handlePageChange" size="lg" v-model="currentPage"
                            align="center" :per-page="10" :current-page="currentPage">
                        </b-pagination> -->
                        <!-- /.row -->
                    </section>
                    <!-- /.content -->
                </div>
            </div>
        </div>
        <Chats />
    </div>
</template>

<script>
import Header from "../../headers/Header.vue";
import MenuLocal from "../../navs/MenuLocal.vue";
import Chats from "../../navs/Chats.vue";
import { mapState } from "vuex";
import {
    required,
    minLength,
    alpha,
    email,
    maxLength,
    sameAs
} from "vuelidate/lib/validators";
import { log } from "util";

export default {
    components: { Header, MenuLocal, Chats },
    data() {
        return {
            UserData: [],
            EtabInfos: "",
            rows: 0,
            currentPage: 0,

            data: {
                sigleClasse: "",
                MontantScol: "",
                FraisInscrip: "",
                MontantScolAffect: "",
                imageEmploiTmp: ""
            },

            datas: [],
            IdParentInfolocal: "",
            showDelateModal: false,
            i: -1,
            datas: [],
            classeItem: ""
        };
    },

    methods: {

        handlePageChange(value) {

            this.EtabInfos[0]['currentPage'] = value - 1

            console.log(value)

            this.getAll()
        },
        Details(data, i) {
            localStorage.setItem("IdParentInfolocal", JSON.stringify(data));
        },

        showDelatingModal(data, i) {
            this.delateItem = data;
            this.i = i;
            this.showDelateModal = true;
        },

        async delateParent() {
            console.log(this.delateItem);
            const response = await axios.post(
                "api/locale/delateParent",
                this.delateItem
            );
            if (response.status === 200) {
                this.datas.splice(this.i, 1);
                this.showDelateModal = false;
                this.s("Parent supprimé correctement");
            }
            // this.modal2 = false;
        },

        async getAll() {

            const response2 = await this.callApi(
                "post",
                "api/locale/getParent",
                this.EtabInfos
            );

            this.datas = response2.data.parents;

            this.rows = response2.data.totalPages

            if (response2.status == 200) {
                // this.$Loading.finish();
                this.$Spin.hide();
            }
        }
    },

    async mounted() {
        this.$Spin.show();

        if (!localStorage.users) {
            this.$router.push("login");
        }

        // Recuperer toutes les infos de cette ecole dans le storage

        if (localStorage.EtabInfos) {
            this.EtabInfos = JSON.parse(localStorage.getItem("EtabInfos"));
        }

        // Recuperer tous les parents de cette ecole


        this.EtabInfos[0]['currentPage'] = this.currentPage;

        this.getAll();
    }
};
</script>

<style>
.content-wrapper {
    background-color: #fafbfd;
}

.demo-upload-list {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
}

.demo-upload-list img {
    width: 100%;
    height: 100%;
}

.demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
    display: block;
}

.demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
}
</style>
